/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */

import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Observer, Subject, combineLatest, filter, map, takeUntil } from 'rxjs';
import { environment } from '../environments/environments';
import { DEFAULT_SETTINGS, Settings } from './model/settings';
import { SessionService } from './services/session.service';
import { PATH_HOUR, PATH_ORDER, PATH_POST, PATH_PROJ, SettingsService, availableLinks } from './services/settings.service';
import { EmployeeService } from './services/employee.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Employee } from './model/employee';




@Component({
    selector: 'app-root',
    standalone: true,
    providers: [
      //provideHttpClient(withInterceptorsFromDi())
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [
        
// TODO: `HttpClientModule` should not be imported into a component directly.
// Please refactor the code to add `provideHttpClient()` call to the provider list in the
// application bootstrap logic and remove the `HttpClientModule` import from this component.
//HttpClientModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        RouterLink,
        RouterLinkActive,
        RouterOutlet
    ]
})

export class AppComponent implements OnInit, OnDestroy {
  public linkTitle = (link: string) => {
    switch(link) {
      case PATH_HOUR: return "Timer";
      case PATH_PROJ: return "Projekter";
      case PATH_ORDER: return "Produktion";
      case PATH_POST: return "Poster";
    }
    return "";
  }

  settings = DEFAULT_SETTINGS;
  links: string[] = availableLinks(this.settings);
  destroyed = new Subject<void>();

  currentScreenSize: string = "";
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  employeeName = '';

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    public session: SessionService,
    private settingsServe: SettingsService,
    private employeeServe: EmployeeService,
    breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }

  ngOnInit(): void {
    if(!environment.production)
      console.log("App ngOnInit");
    this.setupSubscription();
    this.setupEmployeeSubscription();
    if(this.session.isLoggedIn()) {
      this.settingsServe.updateSettings();
      this.employeeServe.updateEmployee();
    }
  }
  
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
  
  private setupSubscription() {
    combineLatest([
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)),
      this.settingsServe.settings$
    ])
    .pipe(
      map(([navEnd, settings]) => settings),
      takeUntil(this.destroyed),
    )
    .subscribe(this.settingSubscription);
  }
  
  private setupEmployeeSubscription() {
    this.employeeServe.employee$
    .pipe(takeUntil(this.destroyed))
    .subscribe(this.employeeSubscription)
  }

  private employeeSubscription: Observer<Employee> = {
    next: (employee) => {
      this.employeeName = employee.employeeName;
    },
    error: () => {},
    complete: () => {
      setTimeout(() => {this.setupEmployeeSubscription()}, 1000);
    }
  }

  private settingSubscription: Observer<Settings> = {
    next: (settings) => {
      //Update which links can be navigated to
      this.settings = settings;
      this.links = availableLinks(this.settings);
      this.cd.detectChanges();

      //If user is currently on unavailable path, navigate them home.
      let basePath = this.getPath().substring(1).split('/')[0];
      if(basePath === 'settings' || basePath === 'change-log') {
        return;
      }
      if(!this.links.find(x => x.substring(1) === basePath)) {
        this.router.navigate([availableLinks(settings)[0]]);
      }
    },
    error: () => {},
    complete: () => {
      setTimeout(() => {this.setupSubscription()}, 1000);
    }
  }

  public logout(): void {
    this.session.clear();
    this.destroyed.next();
    this.router.navigate(['login']);
  }

  public getPath(): string {
    return this.router.url.split('?')[0].split('#')[0];
  }

  public showNavBar(): boolean {
    if(!this.session.isLoggedIn())
      return false;
    let url = this.getPath();
    return false 
      || availableLinks(this.settings).indexOf(url) !== -1
      || url === "/change-log" 
      || url.startsWith("/settings")
      ;
  }
}