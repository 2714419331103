<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>Login</mat-card-title>
        <mat-divider></mat-divider>
    </mat-card-header>
    <form [formGroup]="loginForm" (submit)="onLogin()">
        <mat-card-content>
            <mat-form-field>
                <mat-label>Firma Id</mat-label>
                <input matInput
                    type="number"
                    required
                    minlength="1"
                    formControlName="companyIdControl">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Brugernavn</mat-label>
                <input matInput
                    autocapitalize="none"
                    required
                    minlength="1"
                    formControlName="usernameControl">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Kodeord</mat-label>
                <input matInput
                    
                    [type]="showPassword ? 'text' : 'password'"
                    required
                    minlength="1"
                    formControlName="passwordControl">
                <button mat-icon-button (mousedown)="showPassword = !showPassword" matSuffix>
                    <mat-icon>{{showPassword ? "visibility" : "visibility_off"}}</mat-icon>
                </button>
            </mat-form-field>
        
        </mat-card-content>
        <mat-card-actions>
            <mat-checkbox color="primary" class="checkbox" formControlName="persistSessionControl">
                Forbliv logget ind
            </mat-checkbox>
            <button mat-raised-button color="primary" type="submit" (click)="onLogin()"
                class="login-button" [disabled]="loading || !loginForm.valid" 
            >
                @if(loading) {
                    <!-- <span class="spinner-border spinner-border-sm me-1"></span> -->
                    <mat-icon><mat-spinner diameter="20">
                    </mat-spinner></mat-icon>
                }
                <span>Login</span>
            </button>
        </mat-card-actions>
    </form>
    <mat-card-footer>

    </mat-card-footer>
</mat-card>