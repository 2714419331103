/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { filter, finalize, map, merge, take, throwError, timeout } from 'rxjs';
import { environment } from '../../environments/environments';
import { SessionService } from '../services/session.service';
import { SettingsService, availableLinks } from '../services/settings.service';
import { Settings } from '../model/settings';
import { EmployeeService } from '../services/employee.service';
import { Session } from '../model/session';

const LOCAL_LOGIN = 'flcnflgncidun';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit { //,AfterViewInit
  @Input() 
  set id(id: number) {
    if(!!id) {
      this.loginForm.controls.companyIdControl.setValue(id);
      this._id = id;
    }
  }
  _id?: number;
  @Input() 
  set user(user: string) {
    if(!!user){
      this.loginForm.controls.usernameControl.setValue(user);
      this._user = user;
    }
  }
  _user?: string;
  loginForm = new FormGroup({
    companyIdControl: new FormControl<number>(environment.login_default_companyId),
    usernameControl: new FormControl<string>(environment.login_default_username),
    passwordControl: new FormControl<string>(environment.login_default_password),
    persistSessionControl: new FormControl<boolean>(false),
  });
  
  loading = false;
  showPassword = false;
  session: SessionService = inject(SessionService);
  settingsServe: SettingsService = inject(SettingsService);
  employeeServe: EmployeeService = inject(EmployeeService);

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    const seconds = 1000;
    //If the browser has a token in local storage
    if(this.session.isLoggedIn()) {
      let token = this.session.getToken()!;
      let settings = this.session.settings;



      // If the user used a login url && the session doesn't match the url 
      if((!!this._id && (token.companyId !== this._id)) 
      || (!!this._user && (token.username !== this._user))
      ){
        // Clear the session, and allow the new user to log in.

      }
       // If the user didn't use a login url
      if((!this._id && !this._user) 
      || // Or, the user used a login url AND the saved session matches the login url
            (token.companyId === this._id && token.username === this._user)) {
        //Load the settings //TODO, store the settings along the login info
        this.loading = true;
        this.settingsServe.updateSettings();
        merge(this.settingsServe.errorStream.asObservable(), this.settingsServe.settings$) 
        .pipe(
          take(1),
          // timeout({
          //   each: 10*seconds,
          //   with: () => throwError(() => new Error("Server didn't provide user settings."))
          // }),
          finalize(() => {
            this.loading = false
          }),
        )
        .subscribe(val => {
          if(val instanceof HttpErrorResponse) {
            this.loading = false; 
            this.displayError(val);
            return;
          }
          let settings = val as Settings;
          let firstPage = availableLinks(settings)[0];
          this.router.navigate([firstPage]);
          this.employeeServe.updateEmployee();
        })
      }
    }

    let lgn = window.localStorage.getItem(window.btoa(LOCAL_LOGIN));
    if(!lgn) return;
    let a = JSON.parse(window.atob(lgn)) as {a:number, b:string};

    if(!this._id  && !!a.a && environment.production) {
      this.loginForm.controls.companyIdControl.setValue(a.a);
    }

    if(!this._user && !!a.b && environment.production) {
        this.loginForm.controls.usernameControl.setValue(a.b);
    }
  }

  onLogin() {
    const seconds = 1000; //TODO Create a pipe that handles timeouts.
    this.loading = true;
    if (this.loginForm.invalid)
      return;
    this.session.login(
      this.loginForm.controls.usernameControl.value!,
      this.loginForm.controls.passwordControl.value!,
      this.loginForm.controls.companyIdControl.value!,
      this.loginForm.controls.persistSessionControl.value!
    ).then((res: Session) => {
      let settings = res.settings;
      let firstPage = availableLinks(settings)[0];
      this.loading = false; 
      this.router.navigate([firstPage]);


      // this.settingsServe.updateSettings();
      // merge(this.settingsServe.errorStream.asObservable(), this.settingsServe.settings$)
      // .pipe(
      //   take(1),
      //   // timeout({
      //   //   each: 10*seconds,
      //   //   with: () => throwError(() => new Error("Server didn't provide user settings."))
      //   // }),
      //   finalize(() => {
      //     this.loading = false
      //   }),
      // )
      // .subscribe(val => {
      //   if(val instanceof HttpErrorResponse) {
      //     this.loading = false; 
      //     this.displayError(val);
      //     return;
      //   }
      //   let settings = val as Settings;
      //   let firstPage = availableLinks(settings)[0];
      //   this.router.navigate([firstPage]);
      //   this.loading = false; 
      // })//.catch(err => this.displayError(err));
    }).catch(err => this.displayError(err));
  }

  displayError(error: any) {
    if(error instanceof HttpErrorResponse) {
      if(error.error instanceof ProgressEvent) {
        this._snackBar.open("No internet connection" ,'Ok', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 5000,
        })
        this.loading = false;
        return;
      }
      this._snackBar.open(error.error ,'Ok', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000,
      })
    }
    this.loading = false;
  }
}
