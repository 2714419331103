import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { SessionService } from './services/session.service';

export const sessionGuard: CanActivateFn = (next, state) => {
  let loggedIn = inject(SessionService).isLoggedIn()
  if(!loggedIn) 
    return createUrlTreeFromSnapshot(next, ['/login'])
  return true;
};
