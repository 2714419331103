/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import { ResolveFn, Routes } from '@angular/router';
import { canDeactivateGuard } from './pending-changes.guard';
import { LoginComponent } from './login/login.component';
import { sessionGuard } from './session-guard.guard';

const projectIdTitle: ResolveFn<string> = (route, state) => 
    Promise.resolve('NemFlex Projekt ' + route.paramMap.get("projectId"));

export const routes: Routes = [ 
    { 
        title: 'NemFlex Login',
        path: 'login', 
        component: LoginComponent
    },

    {
        title: "Indstillinger",
        path: "settings",
        loadComponent: () => import("./client-settings/client-settings.component")
            .then(m => m.ClientSettingsComponent),
        canActivate: [sessionGuard],
        children: [
            {
                path: "project",
                loadComponent: () => import("./client-settings/project-table-customizer/project-table-customizer.component")
                    .then(m => m.ProjectTableCustomizerComponent),
                canActivate: [sessionGuard]
            },
            // {
            //     path: "project-line",
            //     loadComponent: () => import("./client-settings/project-line-table-customizer/project-line-table-customizer.component")
            //         .then(m => m.ProjectLineTableCustomizerComponent),
            //     canActivate: [sessionGuard]
            // },
        ]
    },

    {
        title: "Change Log",
        path: "change-log",
        loadComponent: () => import("./change-log/change-log.component")
            .then(m => m.ChangeLogComponent),
        canActivate: [sessionGuard]
    },
    
    {
        title: "NemFlex Timer",
        path: 'hour-overview/add',
        loadComponent: () => import('./hour-overview/time-journal-projects/create-time-journal.component')
            .then(m => m.CreateTimeJournalComponent),
        canActivate: [sessionGuard]
    }, { 
        title: 'NemFlex Timer',
        path: 'hour-overview', 
        loadComponent: () => import('./hour-overview/hour-overview.component')
            .then(m => m.HourOverviewComponent),
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },
    
    {
        title: projectIdTitle, 
        path: 'project-overview/:projectId/edit/:lineId', 
        loadComponent: () => import('./project-overview/project/edit-project-line/edit-project-line.component')
                .then(m => m.EditProjectLineComponent), 
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },{
        title: projectIdTitle, 
        path: 'project-overview/:projectId/attach', 
        loadComponent: () => import('./project-overview/project/attachment-uploader/attachment-uploader.component')
                .then(m => m.AttachmentUploaderComponent), 
        canDeactivate: [canDeactivateGuard],
        canActivate: [sessionGuard]
    },{
        title: projectIdTitle, 
        path: 'project-overview/:projectId', //TODO https://angular.dev/guide/routing/common-router-tasks#setting-the-page-title
        loadComponent: () => import('./project-overview/project/project.component')
            .then(m => m.ProjectComponent),
        canActivate: [sessionGuard]
    },{ 
        title: 'NemFlex Projekter',
        path: 'project-overview', 
        loadComponent: () => import('./project-overview/project-overview.component')
            .then(m => m.ProjectOverviewComponent),
        canActivate: [sessionGuard]
    },

    {
        title: 'NemFlex Produktionsordre',
        path: 'production-order',
        loadComponent: () => import('./production-order/production-order.component')
            .then(m => m.ProductionOrderComponent),
        canActivate: [sessionGuard]
    },{
        title: 'NemFlex Produktionsordre',
        path: 'production-order/:prodOrdNr',
        loadComponent: () => import('./production-order/production-order-lines/production-order-lines.component')
            .then(m => m.ProductionOrderLinesComponent),
        canActivate: [sessionGuard]
    },

    {
        title: 'NemFlex Overblik',
        path: 'overview',
        loadComponent: () => import('./activity-overview/activity-overview.component')
            .then(m => m.ActivityOverviewComponent),
        canActivate: [sessionGuard]
    },

    
    { 
        title: 'NemFlex Login',
        path: '', 
        component: LoginComponent
    },
    { 
        path: '**', 
        redirectTo: '/..', 
        pathMatch: 'full'
    },
]